<template>
  <div>
    <div class="vx-row mb-15 w-full">
      <div class="vx-row m-6 w-2/3">
        <span>Batch Date</span>

        <div class="vx-col ml-3 sm:w-3/5">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="right"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :maxDate="new Date()"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="dates"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
        <vs-button
          @click="onClickBtnFilter"
          size="medium"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Filter</vs-button
        >
      </div>
    </div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          v-model="itemSelected"
          multiple
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @change-page="handleChangePage"
          @search="handleSearch"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <!-- <vs-th
              ><vs-checkbox
                v-model="selectAll"
                @input="handleSelectAll"
              />ALL</vs-th
            > -->
            <!-- <vs-th>Select</vs-th> -->
            <vs-th>View</vs-th>
            <vs-th sort-key="code">Batch</vs-th>
            <vs-th sort-key="name"># of DO</vs-th>
            <vs-th sort-key="name">Total Weight (Kg)</vs-th>
            <vs-th sort-key="name">Total Volume (M3)</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <!-- <vs-td>
                <div class="vx-input-group flex">
                  <vs-checkbox
                    v-model="cartDeliveryPlan"
                    :vs-value="tr.id"
                    color="success"
                    @input="handleClickCheckBox"
                  />
                </div>
              </vs-td> -->
              <vs-td>
                <feather-icon
                  icon="EyeIcon"
                  class="cursor-pointer mr-1 mt-1"
                  svgClasses="w-4 h-4"
                  @click="viewDetail(tr.delivery_order)"
                />
              </vs-td>
              <vs-td :data="data[indextr].transport_delivery_code">
                {{ data[indextr].transport_delivery_code }}
              </vs-td>
              <vs-td :data="data[indextr].total_do">
                {{ data[indextr].total_do }}
              </vs-td>
              <vs-td :data="data[indextr].total_weight">
                {{ data[indextr].total_weight }}
              </vs-td>
              <vs-td :data="data[indextr].total_volume">
                {{ data[indextr].total_volume }}
              </vs-td>
              <!-- detail delivery orders -->
              <!-- <template slot="expand">
            <div class="vx-row" :style="{ overflow: 'auto' }">
              <div class="vx-col">
                <vs-table
                  :style="{ width: '100%' }"
                  :sst="false"
                  :data="tr.delivery_order ? tr.delivery_order : []"
                  :total="
                    tr.delivery_order.length ? tr.delivery_order.length : 0
                  "
                  :searchable="false"
                >
                  <template slot="thead">
                    <vs-th>Delivery Order Code</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <b>{{ tr.delivery_order_code }}</b>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </template> -->
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full">
            <vs-button class="mr-3 mb-2" v-on:click="submit()"
              >Submit</vs-button
            >
          </div>
        </div>
      </div>
      <!--view detail ----->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShowV2 : '', detailHide]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <!-- form component -->
            <Table2
              @close="handleClose"
              :dataViews="dataViews"
              :isNew="true"
              @sendData="DataCheck($event)"
            ></Table2>
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Table2 from "../tableView.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    Table2,
    DateRangePicker,
  },

  data() {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    let tomorrowFormatted = moment(tomorrow).format("YYYY-MM-DD");

    return {
      itemSelected: [],
      deleteId: null,
      detailShow: "core vx-col md:w-1/4 w-full mb-base ",
      detailShowV2: "core vx-col md:w-3/4 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      table: this.tableDefaultState(),
      exportID: [],
      IDwasGenerate: [],
      selectAll: false,
      date: tomorrowFormatted,
      cartDeliveryPlan: [],
      IDItem: [],
      detail: false,
      dataViews: [],
      today: moment().format("YYYY-MM-DD"),
      filterDate: this.$store.state.tms.tms.date,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    viewDetail(item) {
      this.dataViews = [];
      this.detail = true;
      this.dataViews = item;
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    handleClose() {
      this.detail = false;
      this.dataViews = [];
    },

    getData() {
      this.$vs.loading();
      this.table.data = [];

      this.$http
        .get("/api/wms/v1/transport-code/all", {
          params: {
            page: this.table.page,
            length: this.table.length,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            start_date: moment(this.$store.state.tms.tms.date.startDate).format(
              "YYYY-MM-DD"
            ),
            end_date: moment(this.$store.state.tms.tms.date.endDate).format(
              "YYYY-MM-DD"
            ),
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartDeliveryPlan);
      this.$emit("selected-items", this.cartDeliveryPlan);
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    handleSelectAll(id) {
      if (id == false) {
        this.exportID = [];
      } else {
        this.exportID = this.IDItem;
      }
      this.$emit("selected-items", this.IDItem);
    },
    onClickBtnFilter() {
      this.getData();
    },
    selectItem(item) {
      let check = this.cartDeliveryPlan.find(
        (cartItem) => cartItem.deliveryPlanId === item.id
      );

      if (!check) {
        this.cartDeliveryPlan.push({
          warehouseId: item.warehouse_id,
          deliveryPlanId: item.id,
          deliveryPlanCode: item.transport_delivery_code,
          delvieryPlanDate: this.date,
          totalVolume: item.total_volume,
          totalWeight: item.total_weight,
          methodPlan: item.picking_method,
          zoneID: item.zone_id,
          deliveryOrder: item.delivery_order,
        });
      } else {
        this.cartDeliveryPlan = this.cartDeliveryPlan.filter(
          (cartItem) => cartItem.deliveryPlanId !== item.id
        );
      }
    },
    submit() {
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: "Submit",
        text: "Are you sure you want to submit this data?",
        acceptText: "Submit",
        accept: () => {
          this.SubmitForm();
        },
      });
    },
    SubmitForm() {
      this.handleClose();

      // console.log(">>>>>ini submit", this.cartDeliveryPlan);
      // if (this.cartDeliveryPlan.length == 0) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Please select Item Line first",
      //     color: "danger",
      //     position: "top-right",
      //     iconPack: "feather",
      //     icon: "icon-check",
      //   });
      //   return true;
      // }
      console.log(">>>>>ini submit", this.itemSelected);
      if (this.itemSelected.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      this.IDItem = this.itemSelected.map((item) => item.id);

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/transport-code/", {
          delivery_plan_id: this.IDItem,
          date_delivery: this.date,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  watch: {
    detail() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    dates: {
      get() {
        return this.$store.state.tms.tms.date;
      },
      set(val) {
        this.$store.commit("tms/tms/setDate", val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
